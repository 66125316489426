export interface UserCartItemDTO {
    id: string;
    name: string;
    quantity: number;
    image: string;
    price: number;
    contents: string[];
    note?: string;
}

export interface AddressDTO {
    id: string;
    latitude: number;
    longitude: number;
    nickname: string;
    street: string;
    building: string;
    apartment: string;
    default_address: boolean;
    additional_information?: string;
    user: string;
}

export interface UserDTO {
    id: string;
    email: string;
    name: string;
    phone_number: string;
    date_of_birth: Date;
    loyalty_balance: number;
    verified: boolean;
    notification_options: NotificationOptions;
    cart_number: number;
}

export enum OrderState {
    PENDING = "PENDING",
    PREPARING = "PREPARING",
    DELIVERING = "DELIVERING",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED"
}

export interface OrderDTO {
    id: string;
    items: UserCartItemDTO[];
    order_date: Date;
    state: OrderState;
    discount: number;
    subtotal: number;
    delivery: number;
    total: number;
    address?: AddressDTO;
    user: UserDTO;
}
